import React, { useState, useEffect, useCallback } from "react";
import { Box, Button, LinearProgress, Typography, Fade, CircularProgress, Alert } from "@mui/material";
import StepAge from "./StepAge.js";
import StepLocation from "./StepLocation.js";
import StepPurpose from "./StepPurpose.js";
import StepField from "./StepField.js";
import StepEducationLevel from "./StepEducationLevel.js";
import ResultList from "./ResultList.js";
import { createClient } from "@supabase/supabase-js";
import { searchSimilarScholarships } from "../../lib/searchSimilarScholarships";

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY;
const supabase = createClient(supabaseUrl, supabaseKey);

const WizardContainer = ({ isTestEnvironment }) => {
  const steps = [
    { id: 0, label: "Ange din ålder", component: StepAge },
    { id: 1, label: "Välj plats", component: StepLocation },
    { id: 2, label: "Välj syfte", component: StepPurpose },
    { id: 3, label: "Välj ämnesområde", component: StepField },
    { id: 4, label: "Välj utbildningsnivå", component: StepEducationLevel },
    { id: 5, label: "Dina resultat", component: ResultList },
  ];

  const [currentStep, setCurrentStep] = useState(0);
  const [userInput, setUserInput] = useState({
    age: "",
    isCitizen: true,
    location: { countryIds: [] },
    purpose: [],
    field: [],
    educationLevel: null,
  });

  const [data, setData] = useState({
    countries: [],
    purpose: [],
    field: [],
    educationLevels: [],
  });

  const [loading, setLoading] = useState(true);
  const [scholarships, setScholarships] = useState([]);
  const [allScholarships, setAllScholarships] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [searchError, setSearchError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const { data: cachedData, error } = await supabase
          .from("cached_globals")
          .select("*")
          .single();

        if (error || !cachedData) {
          throw new Error("Kunde inte hämta cached_globals");
        }

        setData({
          countries: cachedData.countries || [],
          purpose: cachedData.purposes || [],
          field: cachedData.fields || [],
          educationLevels: cachedData.education_levels || [],
        });
      } catch (err) {
        console.error("❌ Fel vid hämtning av cached_globals:", err);
        setData({ countries: [], purpose: [], field: [], educationLevels: [] });
      } finally {
        setLoading(false);
      }
    };

    const fetchAll = async () => {
      const { data: allData, error } = await supabase
        .from("cached_scholarships")
        .select("*");

      if (error) {
        console.error("❌ Kunde inte hämta alla stipendier:", error);
        setAllScholarships([]);
      } else {
        setAllScholarships(allData);
      }
    };

    fetchData();
    fetchAll();
  }, []);

  const logSearchToSupabase = async (userText, userInput) => {
    try {
      await supabase.from("search_logs").insert([{ user_text: userText, user_input: userInput }]);
    } catch (err) {
      console.warn("⚠️ Kunde inte logga sökningen:", err);
    }
  };  

  const fetchFilteredScholarships = useCallback(async () => {
    const { age, isCitizen, location, purpose, field, educationLevel } = userInput;

    if (!age || !educationLevel || !field.length || !purpose.length || !location.countryIds.length) {
      setScholarships([]);
      return;
    }

    const selectedEducation = data.educationLevels.find((lvl) => lvl.id === educationLevel)?.name || "";
    const selectedPurpose = data.purpose.filter((p) => purpose.includes(p.id)).map((p) => p.name).join(", ");
    const selectedField = data.field.filter((f) => field.includes(f.id)).map((f) => f.name).join(", ");
    const selectedCountries = data.countries.filter((c) => location.countryIds.includes(c.id)).map((c) => c.name).join(", ");

    const userText = `
      Ålder: ${age}
      Medborgare: ${isCitizen ? "Svensk" : "Ej svensk"}
      Utbildningsnivå: ${selectedEducation}
      Syfte: ${selectedPurpose}
      Ämnesområde: ${selectedField}
      Land: ${selectedCountries}
    `;

    console.log("📝 Genererat användartext för sökning:", userText);

    setIsSearching(true);
    setSearchError(null);

    try {
      await logSearchToSupabase(userText, userInput);

      const results = await searchSimilarScholarships(userText);
      if (!Array.isArray(results)) {
        throw new Error("Ogiltigt svar från AI-sökningen");
      }

      const userSelectedLevel = data.educationLevels.find((lvl) => lvl.id === educationLevel);

      const filtered = results.filter((scholarship) => {
        const cd = scholarship.cached_data;
        if (!cd) return false;

        const edMatch = userSelectedLevel && Array.isArray(cd.education_levels)
          ? cd.education_levels.some((e) => typeof e.education_weight === "number" && e.education_weight <= userSelectedLevel.education_weight)
          : false;

        const fieldMatch = Array.isArray(cd.fields) ? cd.fields.some((f) => field.includes(f.id)) : false;
        const countryMatch = Array.isArray(cd.countries) ? cd.countries.some((c) => location.countryIds.includes(c.id)) : false;
        const purposeMatch = Array.isArray(cd.purposes) ? cd.purposes.some((p) => purpose.includes(p.id)) : false;
        const ageMatch = age >= scholarship.min_age && age <= scholarship.max_age;
        const citizenshipMatch = !scholarship.swe_citizenship_required || isCitizen;

        return edMatch && fieldMatch && countryMatch && purposeMatch && ageMatch && citizenshipMatch;
      });

      console.log(`👉 Antal godkända stipendier: ${filtered.length}`);
      setScholarships(filtered);
    } catch (error) {
      console.error("❌ Fel vid AI-sökning:", error);
      setSearchError("Kunde inte genomföra AI-sökningen. Försök igen senare.");
      setScholarships([]);
    } finally {
      setIsSearching(false);
    }
  }, [userInput, data]);

  useEffect(() => {
    fetchFilteredScholarships();
  }, [fetchFilteredScholarships]);

  const handleDataChange = useCallback((key, value) => {
    setUserInput((prev) => ({ ...prev, [key]: value }));
  }, []);

  const handleAutoFill = useCallback(() => {
    const newState = {
      age: 25,
      isCitizen: true,
      location: { countryIds: [6, 98] },
      purpose: [6],
      field: [3, 4],
      educationLevel: 5,
    };
    setUserInput(newState);
    setTimeout(() => setCurrentStep(5), 300);
  }, []);

  const validateStep = useCallback(() => {
    switch (currentStep) {
      case 0: return !!userInput.age;
      case 1: return userInput.location.countryIds.length > 0;
      case 2: return userInput.purpose.length > 0;
      case 3: return userInput.field.length > 0;
      case 4: return !!userInput.educationLevel;
      default: return true;
    }
  }, [currentStep, userInput]);

  const renderStep = useCallback(() => {
    if (currentStep === 5) {
      return (
        <>
          {isSearching && <CircularProgress sx={{ my: 2 }} />}
          {searchError && <Alert severity="error">{searchError}</Alert>}
          {!isSearching && !searchError && (
            <ResultList
              scholarships={scholarships}
              allScholarships={allScholarships}
              cachedData={data}
              userInput={userInput}
              setUserInput={setUserInput}
              setCurrentStep={setCurrentStep}
              isTestEnvironment={isTestEnvironment}
            />
          )}
        </>
      );
    }

    const StepComponent = steps[currentStep]?.component;
    return StepComponent ? (
      <StepComponent
        userInput={userInput}
        onDataChange={handleDataChange}
        data={data}
        scholarships={scholarships}
        setCurrentStep={setCurrentStep}
      />
    ) : null;
  }, [currentStep, scholarships, allScholarships, data, userInput, isTestEnvironment, handleDataChange, isSearching, searchError]);

  return (
    <Box sx={{ width: "100%", maxWidth: "600px", margin: "auto", textAlign: "center", padding: 4 }}>
      <Typography variant="h5" gutterBottom>{steps[currentStep]?.label}</Typography>

      <LinearProgress
        variant="determinate"
        value={currentStep === steps.length - 1 ? 100 : (currentStep / (steps.length - 1)) * 100}
        sx={{ marginBottom: 2 }}
      />

      <Fade in key={currentStep} timeout={500}>
        <Box>{loading ? <CircularProgress /> : renderStep()}</Box>
      </Fade>

      <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: 4 }}>
        {currentStep > 0 && (
          <Button variant="outlined" onClick={() => setCurrentStep((prev) => prev - 1)}>
            Föregående
          </Button>
        )}
        {currentStep < steps.length - 1 && (
          <Button
            variant="contained"
            onClick={() => {
              if (validateStep()) {
                setCurrentStep((prev) => prev + 1);
              } else {
                alert("Vänligen fyll i alla obligatoriska fält innan du går vidare.");
              }
            }}
          >
            Nästa
          </Button>
        )}
      </Box>

      {currentStep === 0 && (
        <Button variant="contained" color="secondary" onClick={handleAutoFill} sx={{ marginTop: 2 }}>
          Fyll i automatiskt och visa resultat
        </Button>
      )}

      {currentStep < 5 && (
        <Button
          variant="outlined"
          onClick={() => {
            setUserInput({
              age: "",
              isCitizen: true,
              location: { countryIds: [] },
              purpose: [],
              field: [],
              educationLevel: null,
            });
            setCurrentStep(0);
          }}
          sx={{ marginTop: 2 }}
        >
          Återställ formulär
        </Button>
      )}
    </Box>
  );
};

export default WizardContainer;
