import React from "react";
import WizardContainer from "../components/wizard/WizardContainer.js"; // Lägg till .js
import { Box, Typography } from "@mui/material";

const FindScholarshipPage = () => {
  return (
    <Box sx={{ paddingTop: "80px", textAlign: "center" }}>
      <WizardContainer />
    </Box>
  );
};

export default FindScholarshipPage;
