import { createClient } from '@supabase/supabase-js';
import { generateEmbedding } from './embeddingService';

console.log("🔐 SUPABASE URL:", process.env.REACT_APP_SUPABASE_URL);
console.log("🔐 SUPABASE KEY:", process.env.REACT_APP_SUPABASE_KEY);

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_KEY
);

export async function searchSimilarScholarships(userInputText, limit = 10) {
  try {
    console.log("📨 Söker med input:", userInputText);
    const embedding = await generateEmbedding(userInputText);

    if (!embedding) throw new Error("❌ Ingen embedding genererades.");

    const response = await supabase.rpc('match_cached_scholarships', {
      embedding_vector: embedding,
      match_limit: limit
    });

    // Extra skydd om Supabase råkar returnera HTML (t.ex. pga RPC saknas)
    if (!response || typeof response !== 'object') {
      console.error("❌ Supabase svarade med ogiltigt format:", response);
      return [];
    }

    const { data, error } = response;

    // Kolla om det blev fel
    if (error) {
      // Om det är HTML som svar (t.ex. "function not found"), logga det snyggt
      if (typeof error.message === 'string' && error.message.includes('<')) {
        console.error("❌ Supabase RPC verkar inte finnas eller returnerar HTML:", error.message);
      } else {
        console.error("❌ Fel vid RPC-anrop:", error);
      }
      return [];
    }

    // Ingen data? Returnera tomt
    if (!data) {
      console.warn("⚠️ RPC-anropet lyckades men gav ingen data.");
      return [];
    }

    console.log("✅ Fick resultat:", data);
    return data;

  } catch (err) {
    console.error("❌ Fel i sökningen:", err);
    return [];
  }
}
